import { gql } from '@apollo/client';

import { TASK_FRAGMENT } from '../fragments';

export const GET_TASK_DASHBOARD = gql`
  query TaskCounts($projectId: ID!, $workspaceId: ID!) {
    taskCounts(projectId: $projectId) {
      totalTasks
      completedTasks
    }
    taskByStatusWithCount(projectId: $projectId) {
      title
      tasksCount
    }
    dashboardTasksByPeriod(projectId: $projectId, workspaceId: $workspaceId) {
      day
      count
      tasks {
        _id
        title
      }
    }
  }
`;

export const GET_PROJECT_TASKS_COUNT = gql`
  query ProjectTasksCount($projectId: ID!) {
    taskCounts(projectId: $projectId) {
      totalTasks
      completedTasks
      deletedTasks
      archivedTasks
    }
  }
`;

export const GET_SUBTASKS = gql`
  query GetSubtasks($parentId: ID!) {
    subtasks(parentId: $parentId) {
      _id
      title
      statusId
      attachments {
        _id
        url
      }
      parentId {
        _id
        title
      }
    }
  }
`;

export const GET_TASKS = gql`
  query GetTasks(
    $workspaceId: ID!
    $search: String
    $projectId: ID
    $isShowCompleted: Boolean
    $dueDate: String
    $statusIds: [ID!]
  ) {
    getTasks(
      workspaceId: $workspaceId
      search: $search
      projectId: $projectId
      isShowCompleted: $isShowCompleted
      dueDate: $dueDate
      statusIds: $statusIds
    ) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const GET_TASKS_WITH_SUBTASKS = gql`
  query GetTasksWithSubtasks(
    $workspaceId: ID!
    $projectId: ID
    $isShowCompleted: Boolean
    $dueDate: String
    $statusIds: [ID!]
  ) {
    getTasksWithSubtasks(
      workspaceId: $workspaceId
      projectId: $projectId
      isShowCompleted: $isShowCompleted
      dueDate: $dueDate
      statusIds: $statusIds
    ) {
      ...TaskFragment
      subtasks {
        ...TaskFragment
        subtasks {
          ...TaskFragment
          subtasks {
            ...TaskFragment
            subtasks {
              ...TaskFragment
              subtasks {
                ...TaskFragment
              }
            }
          }
        }
      }
    }
  }
  ${TASK_FRAGMENT}
`;

gql`
  query TrashBinTasks($workspaceId: ID!) {
    trashBinTasks(workspaceId: $workspaceId) {
      _id
      title
      deletedAt
      deletedBy {
        _id
        fullName
        profilePicture
      }
      projectId {
        _id
        title
        color
      }
    }
  }
`;

export const GET_PROJECT_TASKS_FOR_BOARD_VIEW = gql`
  query KanbanTasks(
    $projectId: ID!
    $priority: String
    $labels: [ID!]
    $statusIds: [ID!]
    $dueDate: String
    $assignees: [ID!]
  ) {
    kanbanTasks(
      projectId: $projectId
      priority: $priority
      labels: $labels
      statusIds: $statusIds
      dueDate: $dueDate
      assignees: $assignees
    ) {
      _id
      title
      tasksCount
      color
      actions {
        ...TaskFragment
      }
    }
  }
  ${TASK_FRAGMENT}
`;

export const GET_ACTION = gql`
  query GetAction($id: ID!) {
    action(id: $id) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASK_HISTORY = gql`
  query TaskHistory($taskId: ID!) {
    taskHistory(taskId: $taskId) {
      _id
      body
      createdAt
      taskId
    }
  }
`;

gql`
  subscription onCreateTaskHistory {
    onCreateTaskHistory {
      _id
      body
      taskId
      createdAt
    }
  }
`;
