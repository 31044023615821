import { gql } from '@apollo/client';

import { TASK_FRAGMENT } from '../fragments';

gql`
  mutation CreateTask(
    $workspaceId: ID!
    $projectId: ID!
    $title: String!
    $statusId: ID
    $parentId: ID
    $dueDate: Date
  ) {
    createTask(
      workspaceId: $workspaceId
      projectId: $projectId
      statusId: $statusId
      title: $title
      parentId: $parentId
      dueDate: $dueDate
    ) {
      _id
      title
      priority
      statusId
      dueDate
      startDate
      storyPoints
      assignee {
        _id
        email
        fullName
        profilePicture
      }
      parentId {
        _id
        title
      }
      labels {
        _id
        title
        color
      }
      attachments {
        _id
        url
        fileName
      }
    }
  }
`;
export const UPDATE_ACTION_STATUS = gql`
  mutation UpdateActionStatus($id: String!, $statusId: ID!) {
    updateAction(id: $id, input: { statusId: $statusId }) {
      _id
      statusId
      parentId {
        _id
        title
      }
    }
  }
`;

export const UPDATE_TASK_DESCRIPTION = gql`
  mutation UpdateActionDescription($id: String!, $description: String!) {
    updateAction(id: $id, input: { description: $description }) {
      _id
      description
    }
  }
`;

gql`
  mutation DeleteTasks($taskIds: [ID!]!, $projectId: ID!) {
    deleteTasks(taskIds: $taskIds, projectId: $projectId) {
      _id
    }
  }
`;

gql`
  mutation ReorderSubtasks($taskId: ID!, $subtaskOrder: [ID!]!) {
    reorderSubtasks(taskId: $taskId, subtaskOrder: $subtaskOrder) {
      _id
      subtaskOrder
    }
  }
`;

export const ADD_ATTACHMENTS_TO_TASK = gql`
  mutation AddAttachmentsToTask(
    $taskId: ID!
    $projectId: ID!
    $attachments: [Upload!]!
  ) {
    addAttachmentsToTask(
      input: {
        taskId: $taskId
        projectId: $projectId
        attachments: $attachments
      }
    ) {
      _id
      attachments {
        _id
        url
        fileName
        size
        type
      }
    }
  }
`;

export const REMOVE_ACTION_ATTACHMENT = gql`
  mutation UpdateActionRemoveAttachments(
    $id: String!
    $removeAttachment: String!
  ) {
    updateAction(id: $id, input: { removeAttachment: $removeAttachment }) {
      _id
      attachments {
        _id
        url
        size
        type
        fileName
      }
    }
  }
`;

gql`
  mutation DuplicateTask($taskId: ID!) {
    duplicateTask(taskId: $taskId) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

gql`
  mutation UpdateTask($updateTaskInput: UpdateTaskInput!) {
    updateTask(updateTaskInput: $updateTaskInput) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;
