import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useNotification } from 'src/components/design-system';

import { useCurrentUser } from '../graphql/hooks';
import { useTaskById } from 'src/hooks/apollo/tasks';

import { useQueryParams } from './useQueryParams';
import {
  GET_PROJECT_TASKS_FOR_BOARD_VIEW,
  GET_SUBTASKS,
  GET_TASKS,
} from 'src/graphql/actions';

import {
  useDeleteTasksMutation,
  useUpdateActionStatusMutation,
  useCreateTaskMutation,
  useDuplicateTaskMutation,
  KanbanTasksQuery,
  useUpdateTaskMutation,
  UpdateTaskInput,
} from 'src/generated';

type UseTaskProps = {
  parentId?: any;
  statusId?: string;
};

export const useTask = ({ parentId, statusId }: UseTaskProps) => {
  const { id: projectId } = useParams() as any;
  const query = useQueryParams();
  const navigate = useNavigate();
  const taskId = query.get('taskId') as string;
  const user = useCurrentUser();
  const { workspaceId } = user;
  const [searchParams] = useSearchParams();

  const viewType = searchParams.get('view');

  const currentTask = useTaskById({ taskId });

  const notification = useNotification();

  const [createTask] = useCreateTaskMutation({
    update(cache, { data }) {
      if (!data?.createTask) return;
      const { statusId, parentId } = data.createTask;

      if (parentId) {
        // @ts-ignore
        const { subtasks } = cache.readQuery({
          query: GET_SUBTASKS,
          variables: { parentId: taskId },
        });

        cache.writeQuery({
          query: GET_SUBTASKS,
          variables: { parentId: taskId },
          data: { subtasks: [...subtasks, data.createTask] },
        });

        //  update parent totalSubtasksCount field
        cache.modify({
          id: cache.identify({
            _id: taskId,
            __typename: 'Action',
          }),
          fields: {
            totalSubtasksCount: (prev: number) => prev + 1,
          },
        });
      }

      // update task calendar view
      cache.updateQuery(
        {
          query: GET_TASKS,
          variables: { projectId, workspaceId },
        },
        response => {
          if (!response) return;
          const getTasks = response?.getTasks || [];
          return {
            getTasks: [...getTasks, data?.createTask],
          };
        },
      );

      // const cached = cache.readQuery<KanbanTasksQuery>({
      //   query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
      //   variables: { projectId },
      // });
      //
      // if (!cached) return;
      //
      // const { kanbanTasks } = cached;
      //
      // cache.writeQuery({
      //   query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
      //   variables: { projectId },
      //   data: {
      //     kanbanTasks: kanbanTasks.map(column => {
      //       if (column._id === statusId) {
      //         return {
      //           ...column,
      //           tasksCount: column.tasksCount + 1,
      //           actions: [data.createTask, ...column.actions],
      //         };
      //       }
      //       return column;
      //     }),
      //   },
      // });
    },
  });

  const [updateTask] = useUpdateTaskMutation();

  const [updateActionStatus] = useUpdateActionStatusMutation({
    update(cache, { data }) {
      if (!data?.updateAction) return;

      // only need to update cache for kanban view
      // but have some issues with cache update
      // need to fix it later
      if (viewType !== 'kanban') return;

      // if update subtask status, do nothing
      if (parentId) return;

      const { _id: taskId, statusId: newStatusId } = data.updateAction;

      const boards = cache.readQuery({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId },
      });

      if (boards === null) return;

      // @ts-ignore
      const { kanbanTasks } = boards;

      if (statusId === newStatusId) return;

      let found;
      for (const category of kanbanTasks) {
        found = category.actions.find(item => item.statusId === newStatusId);
        if (found) break;
      }

      const task = found || currentTask;

      const newTasks = { ...task, statusId: newStatusId };

      const newStatuses = kanbanTasks.map(status => {
        if (status._id === statusId) {
          return {
            ...status,
            tasksCount: !parentId && status.tasksCount - 1,
            actions: status.actions.filter(action => action._id !== taskId),
          };
        }

        if (status._id === newStatusId) {
          return {
            ...status,
            tasksCount: !parentId && status.tasksCount + 1,
            actions: [newTasks, ...status.actions],
          };
        }

        return status;
      });

      cache.writeQuery({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId },
        data: {
          kanbanTasks: newStatuses,
        },
      });
    },
  });

  const [deleteTask] = useDeleteTasksMutation({
    update(cache, { data }) {
      if (!data?.deleteTasks) return;
      const { deleteTasks } = data;

      deleteTasks.forEach((deletedTask: any) => {
        cache.evict({ id: cache.identify(deletedTask) });
      });
      cache.gc();
    },
    onCompleted: () => {
      notification.show({
        title: 'Task(s) deleted',
        message: 'Task(s) was successfully deleted',
        variant: 'success',
      });
    },
  });

  const [duplicateTask] = useDuplicateTaskMutation({
    update(cache, { data }) {
      if (!data?.duplicateTask) return;
      const { duplicateTask } = data;

      const cached = cache.readQuery<KanbanTasksQuery>({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId },
      });
      if (!cached) return;
      const { kanbanTasks } = cached;

      cache.writeQuery({
        query: GET_PROJECT_TASKS_FOR_BOARD_VIEW,
        variables: { projectId },
        data: {
          kanbanTasks: kanbanTasks.map(column => {
            if (column._id === duplicateTask.statusId) {
              return {
                ...column,
                tasksCount: column.tasksCount + 1,
                actions: [duplicateTask, ...column.actions],
              };
            }
            return column;
          }),
        },
      });
    },
    onCompleted: ({ duplicateTask }) => {
      const { _id } = duplicateTask;

      notification.show({
        title: 'Task duplicated',
        message: 'Task was successfully duplicated',
        variant: 'success',
      });
      query.set('taskId', _id);
      navigate({
        search: query.toString(),
      });
    },
  });

  const onDuplicateTask = async ({ taskId }) => {
    await duplicateTask({
      variables: { taskId },
    });
  };

  const onDeleteTasks = async ({ taskIds }) => {
    await deleteTask({
      variables: { taskIds, projectId },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteTasks: taskIds.map((id: string) => ({
          __typename: 'Action',
          _id: id,
        })),
      },
    });
  };

  const onUpdateTaskStatus = async ({ taskId, statusId }) => {
    await updateActionStatus({
      variables: { id: taskId, statusId },
      optimisticResponse: {
        __typename: 'Mutation',
        updateAction: {
          __typename: 'Action',
          _id: taskId,
          statusId,
        },
      },
    });
  };

  const onCreateTask = ({
    title,
    statusId,
    parentId,
    dueDate,
  }: {
    title: string;
    statusId?: string;
    parentId?: string;
    dueDate?: Date;
  }) => {
    createTask({
      variables: {
        workspaceId,
        projectId,
        statusId,
        title,
        parentId,
        dueDate,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createTask: {
          __typename: 'Action',
          _id: 'temp-id',
          title,
          statusId: 'temp-status-id',
          labels: [],
          dueDate,
        },
      },
    }).then(({ data }) => {
      if (viewType !== 'calendar') return;
      const taskId = data?.createTask._id;
      if (!taskId) return;
      query.set('taskId', taskId);
      navigate({
        search: query.toString(),
      });
    });
  };

  const onUpdateTask = (taskData: UpdateTaskInput) => {
    updateTask({
      variables: {
        updateTaskInput: {
          ...taskData,
        },
      },
      optimisticResponse: {
        // @ts-ignore
        updateTask: {
          ...taskData,
        },
      },
    });
  };

  const onCopyShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    notification.show({
      title: 'Link copied',
      message: 'Link was successfully copied to clipboard',
      variant: 'success',
    });
  };

  const handleOpenTask = ({ taskId }) => {
    query.set('taskId', taskId);
    navigate({
      search: query.toString(),
    });
  };

  return {
    onCreateTask,
    onDeleteTasks,
    onUpdateTaskStatus,
    onDuplicateTask,
    onCopyShareLink,
    onUpdateTask,
    onTaskOpen: handleOpenTask,
  };
};
