import React, { SVGProps } from 'react';

import { ReactComponent as AddUser } from 'src/assets/icons/add-user.svg';
import { ReactComponent as AlignCenter } from 'src/assets/icons/align-center.svg';
import { ReactComponent as AlignLeft } from 'src/assets/icons/align-left.svg';
import { ReactComponent as AlignRight } from 'src/assets/icons/align-right.svg';
import { ReactComponent as Archive } from 'src/assets/icons/archive.svg';
import { ReactComponent as Attachment } from 'src/assets/icons/attachment.svg';
import { ReactComponent as BackArrow } from 'src/assets/icons/back-arrow.svg';
import { ReactComponent as Backward15 } from 'src/assets/icons/backward-15.svg';
import { ReactComponent as Bold } from 'src/assets/icons/bold.svg';
import { ReactComponent as Book } from 'src/assets/icons/book.svg';
import { ReactComponent as Calendar } from 'src/assets/icons/calendar.svg';
import { ReactComponent as CheckListItem } from 'src/assets/icons/check-list-item.svg';
import { ReactComponent as Checkbox } from 'src/assets/icons/checkbox.svg';
import { ReactComponent as CircleCheckFilled } from 'src/assets/icons/circle-check-filled.svg';
import { ReactComponent as CircleCheck } from 'src/assets/icons/circle-check.svg';
import { ReactComponent as CircleDollar } from 'src/assets/icons/circle-dollar.svg';
import { ReactComponent as Circle } from 'src/assets/icons/circle.svg';
import { ReactComponent as CloseTag } from 'src/assets/icons/close-tag.svg';
import { ReactComponent as Close } from 'src/assets/icons/close.svg';
import { ReactComponent as Comments } from 'src/assets/icons/comments.svg';
import { ReactComponent as Copy } from 'src/assets/icons/copy.svg';
import { ReactComponent as CustomFields } from 'src/assets/icons/custom-fields.svg';
import { ReactComponent as Dashboard } from 'src/assets/icons/dashboard.svg';
import { ReactComponent as DotsVertical } from 'src/assets/icons/dots-vertical.svg';
import { ReactComponent as DoubleBackward } from 'src/assets/icons/double-backward.svg';
import { ReactComponent as DoubleForward } from 'src/assets/icons/double-forward.svg';
import { ReactComponent as DragHorizontal } from 'src/assets/icons/drag-horizontal.svg';
import { ReactComponent as DragVertical } from 'src/assets/icons/drag-vertical.svg';
import { ReactComponent as Edit } from 'src/assets/icons/edit.svg';
import { ReactComponent as FileText } from 'src/assets/icons/file-text.svg';
import { ReactComponent as FillDice1 } from 'src/assets/icons/fill-dice-1.svg';
import { ReactComponent as FillDice2 } from 'src/assets/icons/fill-dice-2.svg';
import { ReactComponent as FillDice3 } from 'src/assets/icons/fill-dice-3.svg';
import { ReactComponent as FillDice4 } from 'src/assets/icons/fill-dice-4.svg';
import { ReactComponent as FillDice5 } from 'src/assets/icons/fill-dice-5.svg';
import { ReactComponent as Filter } from 'src/assets/icons/filter.svg';
import { ReactComponent as Folders } from 'src/assets/icons/folders.svg';
import { ReactComponent as FormatColorText } from 'src/assets/icons/format-color-text.svg';
import { ReactComponent as Forward15 } from 'src/assets/icons/forward-15.svg';
import { ReactComponent as HeadingThree } from 'src/assets/icons/heading-three.svg';
import { ReactComponent as HeadingTwo } from 'src/assets/icons/heading-two.svg';
import { ReactComponent as HeadingOne } from 'src/assets/icons/headingOne.svg';
import { ReactComponent as History } from 'src/assets/icons/history.svg';
import { ReactComponent as Home } from 'src/assets/icons/home.svg';
import { ReactComponent as InfoSquare } from 'src/assets/icons/info-square.svg';
import { ReactComponent as InfoTriangleFilled } from 'src/assets/icons/info-triangle-filled.svg';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ReactComponent as Italic } from 'src/assets/icons/italic.svg';
import { ReactComponent as Like } from 'src/assets/icons/like.svg';
import { ReactComponent as Link } from 'src/assets/icons/link.svg';
import { ReactComponent as Mail } from 'src/assets/icons/mail.svg';
import { ReactComponent as Menu } from 'src/assets/icons/menu.svg';
import { ReactComponent as Note } from 'src/assets/icons/note.svg';
import { ReactComponent as Notification } from 'src/assets/icons/notification.svg';
import { ReactComponent as OrderedList } from 'src/assets/icons/orderedList.svg';
import { ReactComponent as PauseSquareFill } from 'src/assets/icons/pause-square-fill.svg';
import { ReactComponent as Pencil } from 'src/assets/icons/pencil.svg';
import { ReactComponent as PinCircle } from 'src/assets/icons/pin-circle.svg';
import { ReactComponent as PlaySquareFill } from 'src/assets/icons/play-square-fill.svg';
import { ReactComponent as PlusSquareFill } from 'src/assets/icons/plus-square-fill.svg';
import { ReactComponent as PlusSquare } from 'src/assets/icons/plus-square.svg';
import { ReactComponent as Plus } from 'src/assets/icons/plus.svg';
import { ReactComponent as Project } from 'src/assets/icons/project.svg';
import { ReactComponent as Puzzle } from 'src/assets/icons/puzzle.svg';
import { ReactComponent as Redo } from 'src/assets/icons/redo.svg';
import { ReactComponent as Reload } from 'src/assets/icons/reload.svg';
import { ReactComponent as Reorder } from 'src/assets/icons/reorder.svg';
import { ReactComponent as Save } from 'src/assets/icons/save.svg';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { ReactComponent as Select } from 'src/assets/icons/select.svg';
import { ReactComponent as Settings } from 'src/assets/icons/settings.svg';
import { ReactComponent as Share } from 'src/assets/icons/share.svg';
import { ReactComponent as Square } from 'src/assets/icons/square.svg';
import { ReactComponent as Sticker } from 'src/assets/icons/sticker.svg';
import { ReactComponent as StopCircleFill } from 'src/assets/icons/stop-circle-fill.svg';
import { ReactComponent as Strikethrough } from 'src/assets/icons/strikethrough.svg';
import { ReactComponent as Subtasks } from 'src/assets/icons/subtasks.svg';
import { ReactComponent as Table } from 'src/assets/icons/table.svg';
import { ReactComponent as Tags } from 'src/assets/icons/tags.svg';
import { ReactComponent as Trash } from 'src/assets/icons/trash.svg';
import { ReactComponent as Underline } from 'src/assets/icons/underline.svg';
import { ReactComponent as Undo } from 'src/assets/icons/undo.svg';
import { ReactComponent as Unlink } from 'src/assets/icons/unlink.svg';
import { ReactComponent as UnOrderedList } from 'src/assets/icons/unordered-list.svg';
import { ReactComponent as Upload } from 'src/assets/icons/upload.svg';
import { ReactComponent as UserCircle } from 'src/assets/icons/user-circle.svg';
import { ReactComponent as VolumeHigh } from 'src/assets/icons/volume-high.svg';
import { ReactComponent as VolumeOff } from 'src/assets/icons/volume-off.svg';
import { ReactComponent as VolumeSmall } from 'src/assets/icons/volume-small.svg';

export type IconName =
  | 'close'
  | 'calendar'
  | 'sticker'
  | 'plus-square-fill'
  | 'play-square-fill'
  | 'pause-square-fill'
  | 'dots-vertical'
  | 'stop-circle-fill'
  | 'pin-circle'
  | 'edit'
  | 'archive'
  | 'trash'
  | 'back-arrow'
  | 'user-circle'
  | 'circle-check-filled'
  | 'subtasks'
  | 'notification'
  | 'reload'
  | 'save'
  | 'menu'
  | 'attachment'
  | 'file-text'
  | 'comments'
  | 'tags'
  | 'unlink'
  | 'upload'
  | 'format-color-text'
  | 'table'
  | 'dashboard'
  | 'folders'
  | 'double-forward'
  | 'double-backward'
  | 'forward-15'
  | 'backward-15'
  | 'note'
  | 'volume-off'
  | 'volume-small'
  | 'volume-high'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'headingOne'
  | 'headingTwo'
  | 'headingThree'
  | 'orderedList'
  | 'unorderedList'
  | 'alignLeft'
  | 'alignRight'
  | 'alignCenter'
  | 'link'
  | 'checkbox'
  | 'plus-square'
  | 'close-tag'
  | 'puzzle'
  | 'filter'
  | 'home'
  | 'book'
  | 'project'
  | 'fill-dice-1'
  | 'fill-dice-2'
  | 'fill-dice-3'
  | 'fill-dice-4'
  | 'fill-dice-5'
  | 'like'
  | 'settings'
  | 'plus'
  | 'copy'
  | 'history'
  | 'share'
  | 'custom-fields'
  | 'mail'
  | 'info-square'
  | 'info'
  | 'reorder'
  | 'circle-check'
  | 'add-user'
  | 'info-triangle-filled'
  | 'circle-dollar'
  | 'check-list-item'
  | 'select'
  | 'square'
  | 'circle'
  | 'pencil'
  | 'undo'
  | 'redo'
  | 'drag-vertical'
  | 'drag-horizontal'
  | 'search';

export const iconsRegistry: Record<
  IconName,
  React.ComponentType<SVGProps<SVGSVGElement>>
> = {
  close: Close,
  calendar: Calendar,
  sticker: Sticker,
  'plus-square-fill': PlusSquareFill,
  'play-square-fill': PlaySquareFill,
  'pause-square-fill': PauseSquareFill,
  'stop-circle-fill': StopCircleFill,
  'dots-vertical': DotsVertical,
  'pin-circle': PinCircle,
  edit: Edit,
  archive: Archive,
  trash: Trash,
  'back-arrow': BackArrow,
  'user-circle': UserCircle,
  'circle-check-filled': CircleCheckFilled,
  subtasks: Subtasks,
  notification: Notification,
  reload: Reload,
  save: Save,
  menu: Menu,
  attachment: Attachment,
  'file-text': FileText,
  comments: Comments,
  tags: Tags,
  unlink: Unlink,
  upload: Upload,
  'format-color-text': FormatColorText,
  table: Table,
  dashboard: Dashboard,
  folders: Folders,
  'double-forward': DoubleForward,
  'double-backward': DoubleBackward,
  'forward-15': Forward15,
  'backward-15': Backward15,
  note: Note,
  'volume-off': VolumeOff,
  'volume-small': VolumeSmall,
  'volume-high': VolumeHigh,
  bold: Bold,
  italic: Italic,
  underline: Underline,
  strikethrough: Strikethrough,
  headingOne: HeadingOne,
  headingTwo: HeadingTwo,
  headingThree: HeadingThree,
  orderedList: OrderedList,
  unorderedList: UnOrderedList,
  alignLeft: AlignLeft,
  alignRight: AlignRight,
  alignCenter: AlignCenter,
  link: Link,
  checkbox: Checkbox,
  'plus-square': PlusSquare,
  'close-tag': CloseTag,
  puzzle: Puzzle,
  filter: Filter,
  home: Home,
  book: Book,
  project: Project,
  'fill-dice-1': FillDice1,
  'fill-dice-2': FillDice2,
  'fill-dice-3': FillDice3,
  'fill-dice-4': FillDice4,
  'fill-dice-5': FillDice5,
  like: Like,
  settings: Settings,
  plus: Plus,
  copy: Copy,
  history: History,
  share: Share,
  'custom-fields': CustomFields,
  mail: Mail,
  'info-square': InfoSquare,
  info: Info,
  reorder: Reorder,
  'circle-check': CircleCheck,
  'add-user': AddUser,
  'info-triangle-filled': InfoTriangleFilled,
  'circle-dollar': CircleDollar,
  'check-list-item': CheckListItem,
  select: Select,
  square: Square,
  circle: Circle,
  pencil: Pencil,
  undo: Undo,
  redo: Redo,
  'drag-vertical': DragVertical,
  'drag-horizontal': DragHorizontal,
  search: Search,
};

interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName;
  size?: number;
}

const Icon = ({ name, color, size = 18, ...rest }: IconProps) => {
  const Component = iconsRegistry[name];

  if (!Component) {
    return null;
  }

  return (
    <Component
      data-name={name}
      color={color}
      width={size}
      height={size}
      {...rest}
    />
  );
};

export default Icon;
