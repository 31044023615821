import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';

export enum DateFormats {
  DayLongMonth = 'dd LLLL',
  DayLongMonthYear = "LLL dd',' R",
  DayShortMonth = 'MMM d',
  FullDate = "EEE, MMM d 'at' HH:mm aaa",
}

export class DateHelpers {
  static formatDate(date?: Date | null, formatType = DateFormats.DayLongMonth) {
    if (!date) return null;
    return format(new Date(date), formatType);
  }

  isBusinessHours(date: Date) {
    const day: number = getDay(date); // 0 - Sunday, 1 - Monday, ..., 6 - Saturday

    // Перевіряємо, чи день з понеділка до п'ятниці
    if (day === 0 || day === 6) {
      return false; // Неділя або субота
    }

    const startOfWorkDay: Date = setHours(setMinutes(new Date(date), 0), 9); // 09:00
    const endOfWorkDay: Date = setHours(setMinutes(new Date(date), 0), 18); // 18:00

    // Перевіряємо, чи час в межах робочого дня
    return isWithinInterval(date, { start: startOfWorkDay, end: endOfWorkDay });
  }
}
