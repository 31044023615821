import React from 'react';

import { useTask } from 'src/hooks/useTask';

import * as Styled from './styles';

const SelectedRowsActions = ({ selectedRows, table }) => {
  const { onDeleteTasks } = useTask({});

  return (
    <Styled.Container>
      <div>
        {'Rows'} {selectedRows.length}
      </div>

      <div>
        <button
          onClick={() => {
            onDeleteTasks({ taskIds: selectedRows });
            table.toggleAllRowsSelected(false);
          }}
        >
          {'Delete'}
        </button>
      </div>
    </Styled.Container>
  );
};

export default SelectedRowsActions;
