import React from 'react';

import ProjectActivityFeeds from './ProjectActivityFeeds/ProjectActivityFeeds';
import ProjectDescription from './ProjectDescription/ProjectDescription';
import ProjectStatus from './ProjectStatus/ProjectStatus';

import * as Styled from './styles';

const ProjectOverview = () => {
  return (
    <Styled.Container>
      <ProjectStatus />
      <ProjectDescription />
      <ProjectActivityFeeds />
    </Styled.Container>
  );
};

export default ProjectOverview;
