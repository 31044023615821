import { Flex } from 'src/components/design-system';
import styled from 'styled-components';

export const ActivityFeedStory = styled(Flex)``;

export const ActivityFeedStoryLeft = styled(Flex)`
  flex-direction: column;
  flex: 0 0 16px;
  margin-right: 8px;
  display: flex;
`;

export const ActivityFeedStoryIcon = styled(Flex)`
  fill: #d84f67;
  align-items: center;
  padding: 4px 0;
  display: flex;
  svg {
    fill: #de5f73;
  }
`;

export const ActivityFeedStoryLine = styled(Flex)`
  flex: 1 0 auto;
  min-width: 1px;
  position: relative;
  &:before {
    border: 1px solid #edeae9;
    content: ' ';
    border-radius: 4px;
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7px;
  }
`;

export const ActivityFeedStoryBody = styled.div`
  min-width: 1px;
  margin-bottom: 32px;
`;

export const ActivityFeedStoryTitle = styled.h5`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin: 0 0 5px 0;
`;

export const ActivityFeedStoryTime = styled.div`
  font-size: 12px;
  color: #6d6e6f;
`;
