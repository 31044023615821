import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { Box } from 'src/components/design-system';

import { useQueryParams } from 'src/hooks/useQueryParams';

import UnsplashImageList from './UnsplashImageList';
import debounce from 'lodash/debounce';
import { ADD_ATTACHMENTS_TO_TASK } from 'src/graphql/actions';

import * as Styled from './styles';

const baseURL = 'https://api.unsplash.com';
const PER_PAGE = 70;

export const getTrendingImages = async ({ search }) => {
  let url = `${baseURL}/photos?per_page=${PER_PAGE}`;

  if (search) {
    url = `${baseURL}/search/photos?query=${search}&per_page=${PER_PAGE}`;
  }

  try {
    const res = await fetch(url, {
      headers: {
        Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`,
      },
    });
    if (!res.ok) {
      return;
    }
    const json = await res.json();
    return json;
  } catch (error) {
    console.error('error in making request', error);
  }
};

const Unsplash = () => {
  const [images, setImages] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const query = useQueryParams();
  const taskId = query.get('taskId') as string;
  const { id: projectId } = useParams<{ id: string }>();

  const [addAttachments] = useMutation(ADD_ATTACHMENTS_TO_TASK);

  const getUnsplashImages = async ({ search }) => {
    console.log(search, 'search');

    setIsLoading(true);
    const photos = await getTrendingImages({ search });
    setImages(Array.isArray(photos) ? photos : photos?.results || []);
    setIsLoading(false);
  };

  const debounceFn = useCallback(debounce(getUnsplashImages, 700), []);

  const handleChange = (e: any) => {
    setSearch(e.target.value);
    debounceFn({ search: e.target.value });
  };

  useEffect(() => {
    getUnsplashImages({ search });
  }, []);

  const handleImageClick = async image => {
    const {
      alt_description,
      urls: { small },
    } = image;
    const response = await fetch(small);
    const blob = await response.blob();
    const file = new File([blob], `${alt_description}.jpg`, {
      type: blob.type,
    });
    addAttachments({
      variables: {
        taskId,
        projectId,
        attachments: [file],
      },
    });
  };

  return (
    <Box padding={20}>
      <Styled.Title>{'Unsplash'}</Styled.Title>
      <Styled.Input
        type="text"
        placeholder="Search photos..."
        onChange={handleChange}
        value={search}
      />

      <UnsplashImageList
        isLoading={isLoading}
        images={images}
        onImageClick={handleImageClick}
      />
    </Box>
  );
};

export default Unsplash;
