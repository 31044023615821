import { gql } from '@apollo/client';

export const ASSIGNEE_FIELDS = gql`
  fragment AssigneeFields on Action {
    assignee {
      _id
      email
      fullName
      profilePicture
    }
  }
`;

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    _id
    title
    message
    isRead
    createdAt
    projectId
    type
    createdBy {
      _id
      fullName
      profilePicture
    }
  }
`;
