import React from 'react';
import { useParams } from 'react-router-dom';

import { Flex } from 'src/components/design-system';

import formatDistance from 'date-fns/formatDistance';

import { NotificationTypeEnum, useNotificationsQuery } from 'src/generated';

import * as Styled from './styles';

const ProjectActivityFeeds = () => {
  const { id: projectId } = useParams() as any;

  const { data, loading, error } = useNotificationsQuery({
    variables: {
      projectId,
      type: NotificationTypeEnum.ProjectOverview,
    },
  });

  console.log(data, 'data');

  return (
    <div>
      {data?.notifications.map(({ title, createdBy, createdAt }, index) => (
        <Styled.ActivityFeedStory>
          <Styled.ActivityFeedStoryLeft>
            <Styled.ActivityFeedStoryIcon>
              <svg
                className=""
                viewBox="0 0 32 32"
                aria-hidden="true"
                focusable="false"
              >
                <path d="M16,0c8.8,0,16,7.2,16,16s-7.2,16-16,16S0,24.8,0,16S7.2,0,16,0z"></path>
              </svg>
            </Styled.ActivityFeedStoryIcon>
            {index !== data.notifications.length - 1 && (
              <Styled.ActivityFeedStoryLine />
            )}
          </Styled.ActivityFeedStoryLeft>
          <Styled.ActivityFeedStoryBody>
            <Styled.ActivityFeedStoryTitle>
              {title}
            </Styled.ActivityFeedStoryTitle>
            <Flex gap={10} alignItems="center">
              <div>{createdBy.fullName}</div>
              <Styled.ActivityFeedStoryTime>
                {formatDistance(new Date(createdAt), new Date(), {
                  includeSeconds: true,
                  addSuffix: true,
                })}
              </Styled.ActivityFeedStoryTime>
            </Flex>
          </Styled.ActivityFeedStoryBody>
        </Styled.ActivityFeedStory>
      ))}
    </div>
  );
};

export default ProjectActivityFeeds;
